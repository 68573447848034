.title-logo {
  width: 50px;
  height: 50px;
  border: 5px solid #000;
  background-color: #3ec4d9;
  display: grid;
  justify-content: center;
  align-content:center;
  cursor: pointer
}

.title-logo .logo-text {
  transform: rotate(0deg);
  transition: transform .4s ease-in-out;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 3px;
}

.title-logo:hover {
  opacity: 1;
  transform: rotate(-90deg);
  transition: transform .4s ease-in-out
}

.title-logo:hover .logo-text {
  transform: rotate(90deg);
  transform-origin: center
}

section {
  margin-bottom: 50px; 
}

section.home h1 {
  font-size: 2em;
}

section.about p {
  line-height: 1.8;
}

section.skills {
  margin-bottom: 120px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.game-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
  animation: App-logo-spin infinite 512s linear;
  -webkit-animation: App-logo-spin infinite 512s linear;
  -moz-animation: App-logo-spin infinite 512s linear;
}