*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  border: 8px solid #000;
  min-height: 100vh;
}

::selection{
  background: #3ec4d9;
  color: white
}
::-moz-selection {
  background: #3ec4d9;
  color: white
}

a {
  color: #3ec4d9;
  text-decoration: none;
}